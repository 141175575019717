<template>
  <div class="modal order-modal">
    <div class="overlay" @click="$parent.closeOrderModal"></div>
    <div class="wrapper">
      <div class="container">
        <img src="./../assets/close.svg" @click="$parent.closeOrderModal" class="close" />
        <div class="title small">{{ $t("Payment Method") }}</div>
        <div class="button-list-container">
          <div class="button-list" v-if="paymentMethods && paymentMethods.length">
            <button v-for="(item, i) in paymentMethods" class="img-btn" @click="submit(item.code)" :key="i">
              <div class="image-wrapper">
                <img :src="item.image" class="img" />
              </div>
              <div class="payment-method-title">{{ item.title }}</div>
            </button>
          </div>

          <div v-else class="desc">{{ $t("There are no payment methods available") }}</div>

          <transition name="fade">
            <div class="desc red" v-if="$parent.error">{{ $parent.error }}</div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
  name: 'OrderModal',
  props: ['orderData'],
  data: function () {
    return {

    }
  },
  computed: {
    ...mapGetters('app', ['currency']),

    paymentMethods() {
      return this.$parent.availablePaymentMethods;
    }
  },
  methods: {
    submit(type) {
      this.$emit('orderSubmit', this.orderData, type)
    },
  },
  mounted() {
  }
}
</script>
