<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../assets/logo.svg" class="img" />
          <img src="./../assets/logo-mini.svg" class="mob img" />
        </router-link>
      </div>
      <div class="select-container currency-select-container language-select-container">
        <Select2 v-if="formattedCurrencies.length > 1" class="currency-select" :options="formattedCurrencies"
          :modelValue="currency" @update:modelValue="selectCurrency" placeholder="Select currency" />
      </div>
      <ul class="nav">
        <li class="nav__item" v-for="(item, i) in $parent.nav" :key="i">
          <router-link :to="item.link" :class="{ active: isNavItemActive(item) }">{{ item.title }}</router-link>
        </li>
      </ul>
      <div class="right">
        <router-link to="/cart" v-if="$parent.isAuth" class="icon">
          <img src="./../assets/cart.svg" class="img" />
          <div v-if="cartContents.length" class="indicator">
            {{ cartContents.length }}
          </div>
        </router-link>
        <router-link to="/profile" class="button link-container dropdown-open" v-if="$parent.isAuth">
          <img src="./../assets/user.svg" class="img" />
        </router-link>
        <div class="link-container" v-else>
          <div class="button dark nav__item-signIn" @click="$parent.openSignInModal">
            <a>Sign In</a>
          </div>
          <div class="button modal-open" @click="$parent.openSignUpModal">
            <span>Sign Up</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Select2 from "vue3-select2-component";
import { mapState, mapMutations } from "vuex";

export default {
  name: "Header",
  props: ["cartContents"],
  components: {
    Select2
  },
  mounted() {
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState("app", ["currencies", "currency"]),
    formattedCurrencies() {
      return this.currencies.map(currency => ({
        id: currency.id,
        text: `${currency.text} (${currency.symbol})`,
      }));
    },

    // Find the selected currency object
    selectedCurrencyObject() {
      return this.formattedCurrencies.find(item => item.id === this.currency) || null;
    },
  },
  methods: {
    isNavItemActive(item) {
      const currentPath = this.$route.path;
      if (item.link.includes("home")) {
        return currentPath.includes("home");
      }
      else if (item.link.includes("products")) {
        return currentPath.includes("products");
      }
    },
    ...mapMutations("app", ["setCurrentLanguage", "setCurrency"]),
    selectCurrency(selectedOption) {
      this.setCurrency(selectedOption);
    },

    logout() {
      this.$emit("logout");
    },
  },
};
</script>
