<template>
    <div class="section random-cases-section" v-if="randomCases && randomCases.length">
        <div class="wrapper">
            <div class="slider-header">
                <div class="left">
                    <h2 class="slider-title">Cases</h2>
                </div>
                <div class="right">
                    <router-link to="/products/dota2?type=cases" class="link">View all</router-link>
                </div>
            </div>
            <div class="random-cases-slider" @click="handleClick">
                <VueSlickCarousel v-bind="slickOptions" ref="carousel">
                    <div v-for="(card, index) in randomCases" :key="index" class="card" :data-slug="card.slug">
                        <div class="card-wrapper" :class="card.type">
                            <div class="card-content">
                                <transition name="fade">
                                    <span class="added" v-if="$parent.addToCartChosenItem == card.id">Added</span>
                                </transition>
                                <div class="card-image-container">
                                    <div class="ellipse-background" :class="card.type"></div>
                                    <img :src="card.image_url" :alt="card.name" class="card-image">
                                </div>
                                <div class="card-footer">
                                    <h3>{{ card.name }}</h3>
                                    <p v-html='card.description'></p>
                                </div>
                            </div>
                            <button class="price-button" @click.stop="addToCart(card, card.id, card.item_type)">
                                <span class="price">{{ card.price }} {{ $parent.currency }}</span>
                            </button>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapGetters } from 'vuex';

export default {
    name: 'RandomCasesSlider',
    components: { VueSlickCarousel },
    created() {
        this.fetchRandomCases();
    },
    mounted() {
        this.$nextTick(this.initializeCarousel);
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    data() {
        return {
            slickOptions: {
                infinite: true,
                slidesToShow: this.calculateSlidesToShow(),
                slidesToScroll: this.calculateSlidesToScroll(),
                draggable: true,
                // centerMode: false,
                // initialSlide: 0, 
                autoplay: true,
                autoplaySpeed: 4000,
                // speed: 500,
                arrows: true,
                dots: false,
                // variableWidth: true,
                // centerMode: true,
                // centerPadding: '60px',
            },
            randomCases: [
            ],
        }
    },
    computed: {
        ...mapGetters('app', ['currency', "currencySymbol"]),
    },
    watch: {
        currency() {
            this.fetchRandomCases();
        }
    },
    methods: {
        addToCart(item, id, item_type) {
            this.$parent.addToCart(item, id, item_type);
        },
        handleResize() {
            this.slickOptions.slidesToShow = this.calculateSlidesToShow();
            this.slickOptions.slidesToScroll = this.calculateSlidesToScroll();
        },
        calculateSlidesToShow() {
            let containerWidth;
            if (innerWidth >= 1440) {
                containerWidth = 1280; // fixed container width for large screens
            } else {
                containerWidth = innerWidth - 20; // subtract 10px padding from both sides
            }
            const cardWidth = 280;
            const gap = 24;
            const slidesToShow = Math.floor((containerWidth + gap) / (cardWidth + gap));
            return slidesToShow;
        },
        calculateSlidesToScroll() {
            return this.calculateSlidesToShow();
        },
        initializeCarousel() {
            const carousel = this.$refs.carousel;
            if (carousel && carousel.$el) {
                const slickList = carousel.$el.querySelector('.slick-list');
                if (slickList) {
                    slickList.addEventListener('mousedown', () => {
                        this.isDragging = false;
                    });
                    slickList.addEventListener('mousemove', () => {
                        this.isDragging = true;
                    });
                    slickList.addEventListener('mouseup', () => {
                        setTimeout(() => {
                            this.isDragging = false;
                        }, 10);
                    });
                }
            }
        },
        handleClick(event) {
            const card = event.target.closest('.card');
            if (card && !this.isDragging) {
                const slug = card.dataset.slug;
                this.$router.push(`/packs/${slug}`);
            }
        },
        async fetchRandomCases() {
            this.$http.get(process.env.VUE_APP_API + 'packs' + '?currency=' + this.currency)
                .then((res) => {
                    if (res.status == 200) {
                        this.randomCases = res.data.payload;
                    }
                })
                .catch((res) => {
                    if (res.response.status === 401) {
                        this.$parent.openSignInModal();
                    }
                })
        },
    }
}
</script>


<style lang="scss" scoped>
.random-cases-slider {
    height: auto;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 280px;
    position: relative;
    transition: all 0.3s ease;
    margin: 0 12px;
    cursor: pointer;

    background: #1F233C;
    font-family: "Oxanium", sans-serif;
}

.card {
    display: flex !important;
    align-items: center;
    justify-content: center;
}


.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 50px;
    word-break: break-word;

    .added {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #b63241;
        font-size: 48px;
        z-index: 3;
        // -webkit-text-fill-color: white;
        // -webkit-text-stroke: 1px black;
        text-wrap: nowrap;

    }

    .card-image-container {
        position: relative;
        width: 180px;
        height: auto;
        aspect-ratio: 1 / 1;
        pointer-events: none;
        margin-bottom: 12px;
    }

    .card-image {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 4px;
        color: #fff;
        text-align: center;
    }

    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color: #fff;
        text-align: center;
        max-height: 30px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.price-button {
    width: 100%;
    padding: 12px;
    border: none;
    background: linear-gradient(180deg, #B63241 12.5%, #256885 85.42%);
    cursor: pointer;
}

.price {
    color: white;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    font-family: 'Oxanium', sans-serif;
}

/* Adjust the slick carousel options for the gap between cards */
:deep(.slick-slide) {
    // padding: 0 12px;
}
</style>