<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="left">
            <div class="title big">
              The best place to buy Dota 2 and Rust skins and items
            </div>
            <div class="desc">
              CreepSkins offers a wide selection of Dota 2 and Rust skins and items at competitive prices, user-friendly
              interface, secure payment system, fast delivery and excellent customer service.
            </div>
          </div>
          <div class="right">
            <div class="img-container">
              <img src="./../assets/weapon.png" class="img" />
            </div>
          </div>
        </div>
        <Features />
      </div>

      <RandomCasesSlider />

      <div class="section arrivals-section sale-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">Most Popular</div>
            <router-link to="/products/dota2" class="link">
              <span>See all</span>
              <img src="./../assets/white-arrow.svg" class="img" />
            </router-link>
          </div>
          <SlickItem v-if="$parent.newArrivals.length" :list="$parent.newArrivals" :tag="''"
            :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCart" @goToProduct="goToProduct"
            :currency="currency" />
        </div>
      </div>
      <div class="section more-offers-section">
        <img src="./../assets/more-offers.png" class="img" />
        <div class="wrapper">
          <div class="left">
            <div class="title big">More then 1K offers</div>
            <router-link to="/products" class="button">
              <span>View all offers</span>
            </router-link>
          </div>
          <div class="right">
            <ul class="list">
              <li class="item desc">
                You may pick from a wide variety of skins.
              </li>
              <li class="item desc">
                Get the latest items and skins at low prices once they are
                released.
              </li>
              <li class="item desc">
                You may get exclusive, rare skins and items.
              </li>
              <li class="item desc">
                You can find skins for both Rust and Dota 2 – all in one place!
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section improve-section">
        <div class="wrapper">
          <div class="title big">Improve your inventory with CreepSkins</div>
          <button class="button" @click="$parent.openSignUpModal">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from "../components/SlickItem.vue";
import Features from "../components/Features.vue";
import RandomCasesSlider from "../components/RandomCasesSlider.vue";
export default {
  name: "Home",
  props: ["addToCartChosenItem", "currency"],
  components: {
    SlickItem,
    Features,
    RandomCasesSlider
  },
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
    addToCart(item, id, item_type) {
      this.$emit("addToCart", item, id, item_type);
    },
  },
};
</script>
