<template>
	<div class="item-wrapper">
		<div v-if="tag" class="tag">
			<span>{{ tag }}</span>
		</div>
		<div class="preview">
			<img v-if="item.category == 'Cases'" @click="goToSliderItem(item, item.category)" :src="item.img_url"
				class="img" />
			<img v-else @click="goToSliderItem(item, item.category)" :src="imgDomain + item.img_url" class="img" />
			<transition name="fade">
				<span class="title" v-if="addToCartChosenItem == item.id">Added</span>
			</transition>
		</div>
		<a @click="goToSliderItem(item, item.category)" class="desc">{{ item.title }}</a>
		<div class="price title">
			<span class="discount" v-if="item.old_price && item.old_price != '0.00'">{{ item.old_price }} <span class="currency">{{
			currency }}</span></span>
			<span>{{ item.price }} <span class="currency">{{ currency }}</span></span>
		</div>
		<!-- <div class="desc">{{item.type}}</div> -->
		<a @click="$emit('addToCart', item, item.id, item.item_type)" class="link button">
			<span>Add to Cart</span>
			<img src="./../assets/cart.svg" class="img">
		</a>
	</div>
</template>

<script>

export default {
	name: 'ProductCard',
	props: ['item', 'addToCartChosenItem', 'sliderIsClickble', 'tag', 'currency'],
	data: function () {
		return {
			imgDomain: '',
			appDomain: '',
		}
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		goToSliderItem(item, category) {
			if (category == 'Cases') {
				this.$router.push({ path: `/packs/${item.slug}` });
				return;
			}
			let self = this;
			setTimeout(() => {
				if (self.sliderIsClickble == true) {
					self.$emit('goToProduct', item);
				}
			}, 200)
		},
	},
}
</script>
