<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div :class="['top', { active: filtersIsVisible }]">
            <div class="filters">
              <div class="filters-wrapper">
                <div class="title">Filter</div>

                <!-- Add Game Category Filter -->
                <div class="filters-game-container">
                  <div :class="['title small', { active: !gameListIsVisible }]"
                    @click="gameListIsVisible = !gameListIsVisible">
                    Game
                  </div>
                  <div :class="['select-container', { hidden: !gameListIsVisible }]">
                    <label class="chekbox-label" v-for="(item, i) in gameOptions" :key="i">
                      <div class="chekbox-label-wrapper">
                        <input type="radio" :name="item.key" :value="item.key" v-model="selectedGame" />
                        <div class="checkbox"></div>
                        <span class="title">{{ item.label }}</span>
                      </div>
                    </label>
                  </div>
                </div>

                <template v-for="(filter, filterName) in filters">
                  <div :key="filterName" :class="`filter-${filterName}-container`" v-if="filter.options.length > 1">
                    <div :class="['title small', { active: !filter.visible }]"
                      @click="toggleFilterVisibility(filterName)">
                      {{ formatFilterName(filterName) }}
                    </div>
                    <div :class="['select-container', { hidden: !filter.visible }]">
                      <label class="chekbox-label" v-for="(option, i) in filter.options" :key="i">
                        <div class="chekbox-label-wrapper">
                          <input type="radio" :name="filterName" :value="option"
                            v-model="filters[filterName].selected" />
                          <div class="checkbox"></div>
                          <span class="title">{{ option }}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </template>

                <div class="filters-price-container">
                  <div :class="['title small', { active: !priceListIsVisible }]"
                    @click="priceListIsVisible = !priceListIsVisible">
                    Price
                  </div>
                  <div :class="[
            'select-container select-container-range',
            { hidden: !priceListIsVisible },
          ]">
                    <div class="val-container">
                      <div class="val min">
                        <input type="number" v-model="barMinValue" />
                        <div class="desc">{{ currencySymbol }}</div>
                      </div>
                      <div class="divider">-</div>
                      <div class="val max">
                        <input type="number" v-model="barMaxValue" />
                        <div class="desc">{{ currencySymbol }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="select-container select-container-reset">
                  <div class="button dark" @click="resetFilters">Reset</div>
                </div>
              </div>
            </div>
          </div>
          <div :class="['products-container', { active: !filtersIsVisible }]">
            <div class="sort-checkbox-list">
              <div class="desc">Sort:</div>
              <label class="chekbox-label">
                <div class="chekbox-label-wrapper">
                  <input type="radio" name="desc" value="desc" v-model="sort" />
                  <div class="checkbox"></div>
                  <span class="title">Highest price first</span>
                </div>
              </label>
              <label class="chekbox-label">
                <div class="chekbox-label-wrapper">
                  <input type="radio" name="asc" value="asc" v-model="sort" />
                  <div class="checkbox"></div>
                  <span class="title">Lowest price first</span>
                </div>
              </label>
            </div>
            <transition name="fade">
              <div class="list products" v-if="productList">
                <div class="item" v-for="(item, i) in productList" :key="i">
                  <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="true"
                    @goToProduct="goToProduct" :currency="currencySymbol" @addToCart="addToCart" />
                </div>
              </div>
            </transition>
          </div>
          <pagination :per-page="18" v-if="totalProducts" v-model="activePage" :records="totalProducts"
            @paginate="changePage" />
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Pagination from "vue-pagination-2";
import ProductCard from "../components/ProductCard.vue";
import { mapGetters } from "vuex";
export default {
  name: "ProductListPage",
  props: ["addToCartChosenItem"],
  components: {
    Pagination,
    ProductCard,
  },
  data: function () {
    return {
      imgDomain: "",
      filtersIsVisible: true,
      isResettingFilters: false,
      isInitialLoad: true,
      sort: "desc",
      category: "",
      quality: "",
      activePage: 1,
      totalProducts: null,
      categoryOptions: {},
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 200000,
      productList: {},
      priceListIsVisible: true,

      gameOptions: [
        { key: "dota2", label: "Dota 2" },
        { key: "rust", label: "Rust" }
      ],
      selectedGame: "dota2",
      gameListIsVisible: true,

      showCases: false,
      casesList: [],

      filters: {
        types: {
          options: [],
          selected: 'all',
          visible: true,
          apiParam: 'type'
        },
        qualities: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'quality'
        },
        heroes: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'hero'
        },
        exterior_names: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'exterior_name'
        },
        classes: {
          options: [],
          selected: 'all',
          visible: false,
          apiParam: 'item_class'
        }
      },
    };
  },
  computed: {
    ...mapGetters('app', ['currency', "currencySymbol"]),
  },
  watch: {
    currency: function () {
      this.filterProducts();
    },
    barMinValue: function () {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    barMaxValue: function () {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    sort() {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    filters: {
      deep: true,
      handler() {
        if (!this.isResettingFilters && !this.isInitialLoad) {
          this.activePage = 1;
          this.filterProducts();
        }
      }
    },
    selectedGame(newGame) {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.resetFilters();
        this.fetchFilterOptions();
        this.$router.push({
          name: 'ProductListPage',
          params: { game: newGame },
        });
      }
    },
    $route() {
      if (!this.isResettingFilters && !this.isInitialLoad) {
        this.activePage = 1;
        this.checkRouteParams();
      }
    },
  },
  mounted() {
    this.checkRouteParams();
    this.fetchFilterOptions();
    this.$nextTick(() => {
      this.isInitialLoad = false;
    });
  },
  methods: {
    updateFiltersFromQueryParams() {
      for (const filterName in this.filters) {
        const filter = this.filters[filterName];
        const queryParamValue = this.$route.query[filter.apiParam];
        if (queryParamValue) {
          filter.selected = queryParamValue;
        }
      }
    },
    fetchFilterOptions() {
      this.$http.get(`${process.env.VUE_APP_API}items/filter-data?category=${this.selectedGame}`)
        .then((res) => {
          const data = res.data.payload;
          for (const [key, options] of Object.entries(data)) {
            if (this.filters[key]) {
              let newOptions = ['all', ...options.map((option) => option.toLowerCase())];
              if (this.selectedGame === 'dota2' && key === 'types') {
                newOptions.splice(1, 0, 'cases');
              }
              this.filters[key].options = newOptions;
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching filter options:", error);
        });
    },
    initializeFilters() {
      for (const [filterName, options] of Object.entries(this.filters)) {
        if (options.length > 0) {
          this.$set(this.selectedFilters, filterName, '');
          this.$set(this.filterVisibility, filterName, true);
        }
      }
    },
    toggleFilterVisibility(filterName) {
      this.filters[filterName].visible = !this.filters[filterName].visible;
    },
    formatFilterName(name) {
      return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    resetFilters() {
      this.isResettingFilters = true;
      for (const filterName in this.filters) {
        this.filters[filterName].selected = 'all';
      }
      this.barMinValue = 0;
      this.barMaxValue = 1200;
      this.$nextTick(() => {
        this.isResettingFilters = false;
        this.filterProducts();
      });
    },

    filterProducts() {
      let params = new URLSearchParams();
      params.append('category', this.selectedGame);

      for (const [, filter] of Object.entries(this.filters)) {
        if (filter.selected && !filter.selected.startsWith('all')) {
          params.append(filter.apiParam, filter.selected);
        }
      }

      params.append('price_from', this.barMinValue);
      params.append('price_till', this.barMaxValue);
      params.append('sort', this.sort);
      params.append('page', this.activePage);
      params.append('limit', 18);

      this.$http.get(`${process.env.VUE_APP_API}items/list?${params.toString()}&currency=${this.currency}`)
        .then((res) => {
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
          if (error.response && error.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    checkRouteParams() {
      if (this.$route.params.game) {
        this.selectedGame = this.$route.params.game;
      }

      this.activePage = 1;
      this.updateFiltersFromQueryParams();
      this.filterProducts();
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    addToCart(item, id, item_type) {
      this.$emit("addToCart", item, id, item_type);
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
};
</script>
