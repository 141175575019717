<template>
    <div id="spinner-wrapper">
        <div v-if="loading" class="spinner"></div>
        <div v-else class="check">✔</div>
    </div>
</template>

<style>
#spinner-wrapper {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.check {
    font-size: 120px;
    color: green;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>

<script>
export default {
    data() {
        return {
            loading: true
        }
    },
    async created() {
        try {
            const token = this.$route.query.token;
            const response = await this.$http.get(process.env.VUE_APP_API + `email/verify/${token}`);
            if (response.data.verification === 'success') {
                this.$toast.success('Your email has been verified!');
            } else if (response.data.verification === 'already_verified') {
                this.$toast.info('Your email is already verified.');
            } else {
                this.$toast.error('There was an error verifying your email. Please try again.');
            }
        } catch (error) {
            this.$toast.error('There was an error verifying your email. Please try again.');
        } finally {
            this.loading = false;
            this.$router.push('/home');
        }
    }

};
</script>
